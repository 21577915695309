import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import context from '../Context';
import hseukLogo from './hseuk-logo.svg';
import cioLogo from './cio-logo.svg';
import smartLogo from './smart-logo.svg';
import hseeuLogo from './hseeu-logo.svg';
import rockwellLogo from './rockwell-logo-charcoal.svg';
import ptcLogo from './ptc-logo-charcoal.svg';
import kofaxLogo from './kofax-logo-charcoal.svg';
import arcoLogo from './arco-logo-charcoal.svg';
import sapLogo from './sap-logo-charcoal.svg';
import ulLogo from './ul-logo-charcoal.svg';
import dekraLogo from './dekra-logo-vertical-charcoal.svg';
import saiGlobalLogo from './sai-global-logo-charcoal.svg';
import animmersionLogo from './animmersion-logo-charcoal.svg';
import progarmLogo from './progarm-logo-charcoal.svg';
import dragerLogo from './drager-logo-charcoal.svg';
import centurionLogo from './centurion-logo-charcoal.svg';
import ringersLogo from './ringers-logo-charcoal.svg';
import staySafeLogo from './stay-safe-logo-charcoal.svg';
import scannellLogo from './scannell-logo-charcoal.svg';
import groundworkLogo from './groundwork-logo-charcoal.svg';
import airswebLogo from './airsweb-logo-charcoal.svg';
import enablonLogo from './enablon-logo-charcoal.svg';
import gfgLogo from './gfg-logo-charcoal.svg';
import gensuiteLogo from './gensuite-logo-charcoal.svg';
import thamesWaterLogo from './thames-water-logo-charcoal.svg';
import abbLogo from './abb-logo-charcoal.svg';
import siemensLogo from './siemens-logo-charcoal.svg';
import virginTrainsLogo from './virgin-trains-logo-charcoal.svg';
import integralLogo from './integral-logo-charcoal.svg';
import jcbLogo from './jcb-logo-charcoal.svg';
import rollsRoyceLogo from './rolls-royce-logo-charcoal.svg';
import kongsbergLogo from './kongsberg-logo-charcoal.svg';
import heathrowLogo from './heathrow-logo-charcoal.svg';
import theAaLogo from './the-aa-logo-charcoal.svg';
import centricaLogo from './centrica-logo-charcoal.svg';
import alcatelLucentLogo from './alcatel-lucent-logo-charcoal.svg';
import cbiLogo from './cbi-logo-charcoal.svg';
import maceLogo from './mace-logo-charcoal.svg';
import wincantonLogo from './wincanton-logo-charcoal.svg';
import arrivaLogo from './arriva-logo-charcoal.svg';
import certasEnergyLogo from './certas-energy-logo-charcoal.svg';
import creditSuisseLogo from './credit-suisse-logo-charcoal.svg';
import essentraLogo from './essentra-logo-charcoal.svg';
import highwaysEnglandLogo from './highways-england-logo-charcoal.svg';
import healthAndSafetyExecutiveLogo from './health-and-safety-executive-logo-charcoal.svg';
import isgLogo from './isg-logo-charcoal.svg';
import itvLogo from './itv-logo-charcoal.svg';
import ladbrokesCoralLogo from './ladbrokes-coral-logo-charcoal.svg';
import mitieLogo from './mitie-logo-charcoal.svg';
import mullerLogo from './muller-logo-charcoal.svg';
import portakabinLogo from './portakabin-logo-charcoal.svg';
import saintGobainLogo from './saint-gobain-logo-charcoal.svg';
import stobartGroupLogo from './stobart-group-logo-charcoal.svg';
import thomasCookLogo from './thomas-cook-logo-charcoal.svg';
import toyotaLogo from './toyota-logo-charcoal.svg';
import tullowOilLogo from './tullow-oil-logo-charcoal.svg';
import bathSpaUniversityLogo from './bath-spa-university-logo-charcoal.svg';
import brunelUniversityLogo from './brunel-university-logo-charcoal.svg';
import burohappoldLogo from './burohappold-logo-charcoal.svg';
import clarksLogo from './clarks-logo-charcoal.svg';
import cranfieldLogo from './cranfield-logo-charcoal.svg';
import dhlLogo from './dhl-logo-charcoal.svg';
import imperialCollegeLogo from './imperial-college-logo-charcoal.svg';
import johnsonLogo from './johnson-logo-charcoal.svg';
import kingstonUniversityLogo from './kingston-university-logo-charcoal.svg';
import photoboxLogo from './photobox-logo-charcoal.svg';
import awsLogo from './aws-logo-charcoal.svg';
import bridgeuLogo from './bridgeu-logo-charcoal.svg';
import flowmonLogo from './flowmon-logo-charcoal.svg';
import rackspaceLogo from './rackspace-logo-charcoal.svg';

class OtherEventsPage extends Component {
	render() {
		return (
			<Fragment>
				<div style={{backgroundColor: context.theme.palette.charcoal}}>
					<Container>
						<Row style={styles.eventCardsWrapper}>
							<EventCard link="https://hseuk.co.uk" logo={hseukLogo} date="7th & 8th February" location="Four Seasons Hotel, Hampshire" />
							<EventCard link="https://cioedu.co.uk" logo={cioLogo} date="23rd April" location="Bowood Hotel & SPA, Calne" />
							<EventCard link="https://smartcongress.co.uk" logo={smartLogo} date="6th & 7th June" location="Bowood Hotel & SPA, Calne" />
							<EventCard link="https://hseeu.co.uk" logo={hseeuLogo} date="5th & 6th December" location="Crowne Plaza Den Haag, NL" />
						</Row>
					</Container>
				</div>
				<Container>
					<Row>
						<Col xs="12" style={{paddingTop: 30, textAlign: 'center'}}>
							<div style={styles.rowTitles}>
								Sponsors
								<hr style={styles.hrYellowFade} />
							</div>
						</Col>
					</Row>
					<Row>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://www.rockwellautomation.com" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 20}}} src={rockwellLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://www.ptc.com" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 10}}} src={ptcLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://www.kofax.com" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 20}}} src={kofaxLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://www.arco.co.uk" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 10}}} src={arcoLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://www.sap.com" target="_blank" rel="noopener noreferrer">
								<img style={styles.logoCharcoal} src={sapLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://www.ul.com" target="_blank" rel="noopener noreferrer">
								<img style={styles.logoCharcoal} src={ulLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://www.dekra.com/en-us/home/" target="_blank" rel="noopener noreferrer">
								<img style={styles.logoCharcoal} src={dekraLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://www.saiglobal.com" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 25}}} src={saiGlobalLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="http://www.animmersion.co.uk" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 20}}} src={animmersionLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://www.progarm.com" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 17}}} src={progarmLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://www.draeger.com/en_uk/Home" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={dragerLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://centurionsafety.eu" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 10}}} src={centurionLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="http://ringersgloves.com" target="_blank" rel="noopener noreferrer">
								<img style={styles.logoCharcoal} src={ringersLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://www.staysafeapp.com" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={staySafeLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="http://www.scannellsolutions.com" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={scannellLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="http://www.groundworksafety.com" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={groundworkLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://www.airsweb.com" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 13}}} src={airswebLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://enablon.com" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 20}}} src={enablonLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="http://www.gfg-inc.com/englisch/start.html" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={gfgLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://www.gensuite.com" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={gensuiteLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://aws.amazon.com" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{height: 80, marginTop: 0}}} src={awsLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://bridge-u.com" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={bridgeuLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://www.flowmon.com" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 20}}} src={flowmonLogo} alt="" />
							</a>
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<a href="https://www.rackspace.com" target="_blank" rel="noopener noreferrer">
								<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={rackspaceLogo} alt="" />
							</a>
						</Col>
					</Row>
					<Row>
						<Col xs="12">
							<hr style={styles.hrYellowFade} />
						</Col>
					</Row>
					<Row>
						<Col xs="12" style={{paddingTop: 70, textAlign: 'center'}}>
							<div style={styles.rowTitles}>
								Executive Attendees From
								<hr style={styles.hrYellowFade} />
							</div>
						</Col>
					</Row>
					<Row>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={thamesWaterLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 10}}} src={abbLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 25}}} src={siemensLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={virginTrainsLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={integralLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={jcbLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 20}}} src={rollsRoyceLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 23}}} src={kongsbergLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 20}}} src={heathrowLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={theAaLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 20}}} src={centricaLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 5}}} src={alcatelLucentLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={cbiLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 25}}} src={maceLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 10}}} src={wincantonLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={arrivaLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 10}}} src={certasEnergyLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 17}}} src={creditSuisseLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={essentraLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={highwaysEnglandLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={healthAndSafetyExecutiveLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={isgLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={itvLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 10}}} src={ladbrokesCoralLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 12}}} src={mitieLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={mullerLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 5}}} src={portakabinLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 5}}} src={saintGobainLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 25}}} src={stobartGroupLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={thomasCookLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={toyotaLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={tullowOilLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={bathSpaUniversityLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 5}}} src={brunelUniversityLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 20}}} src={burohappoldLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 10}}} src={clarksLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={cranfieldLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 25}}} src={dhlLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={imperialCollegeLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 25}}} src={johnsonLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={kingstonUniversityLogo} alt="" />
						</Col>
						<Col style={styles.col} xs="6" sm="4" md="3" lg="2">
							<img style={{...styles.logoCharcoal, ...{marginTop: 20}}} src={photoboxLogo} alt="" />
						</Col>
					</Row>
					<Row style={{paddingBottom: 30}}>
						<Col xs="12">
							<hr style={styles.hrYellowFade} />
						</Col>
					</Row>
				</Container>
			</Fragment>
		);
	}
}

export default OtherEventsPage;

const EventCard = props => {
	return (
		<Col style={styles.eventCardWrapper} xs="12" sm="6" lg="3">
			<a href={props.link} target="_blank" rel="noopener noreferrer">
				<img style={styles.eventCardLogo} src={props.logo} alt="" />
				<br />
				<div style={styles.eventCardYear}>
					2018
				</div>
				<div style={styles.eventCardDate}>
					{props.date}
				</div>
				<div>
					{props.location}
				</div>
			</a>
		</Col>
	);
}

const styles = {
	eventCardsWrapper: {
		padding: '50px 0px 20px 0px',
		color: '#fff',
		textAlign: 'center'
	},
	eventCardWrapper: {
		paddingBottom: 30
	},
	eventCardLogo: {
		height: 80
	},
	eventCardYear: {
		display: 'inline-block',
		margin: '10px 0px',
		padding: '0px 10px',
		border: `1px solid ${context.theme.palette.yellow}`,
		fontWeight: 'bold',
		fontSize: '3em'
	},
	eventCardDate: {
		fontSize: '1.2em'
	},
	rowTitles: {
		display: 'inline-block',
		fontWeight: 'bold',
		fontSize: '2em'
	},
	hrYellowFade: {
		margin: '5px 0px',
		border: 0,
		height: 2,
		backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 204, 51, 0.75), rgba(255, 204, 51, 0.75), rgba(255, 204, 51, 0.75), rgba(0, 0, 0, 0))'
	},
	col: {
		height: 100,
		marginBottom: 20,
		textAlign: 'center'
	},
	logoCharcoal: {
		maxWidth: '100%',
		maxHeight: 100,
		padding: 15
	}
}
