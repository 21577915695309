import React, { Component, Fragment } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { Container, Navbar, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import Banner from '../Banner';
import MessageRow from '../MessageRow';
import logoSvg from '../images/htc-logo.svg';
import './index.css';

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onSelect = this.onSelect.bind(this);
		this.state = {
      isOpen: false
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  onSelect(e) {
    this.state.isOpen && this.toggle();
  }

	render() {
		return (
			<Fragment>
				<Container className={css(styles.topWrapper)}>
					<RRNavLink to="/">
						<img className={css(styles.logo)} src={logoSvg} alt="" />
					</RRNavLink>
					<Navbar expand="lg">
						<NavbarToggler onClick={this.toggle} />
						<Collapse isOpen={this.state.isOpen} navbar>
							<Nav navbar>
								<NavItem>
									<NavLink onClick={this.onSelect} to="/" tag={RRNavLink} activeClassName="active" exact>
										HOME
									</NavLink>
								</NavItem>
								<NavItem active>
									<NavLink onClick={this.onSelect} to="/attendees" tag={RRNavLink} activeClassName="active">
										ATTENDEES
									</NavLink>
								</NavItem>
								<NavItem active>
									<NavLink onClick={this.onSelect} to="/venue" tag={RRNavLink} activeClassName="active">
										VENUE
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink onClick={this.onSelect} to="/otherevents" tag={RRNavLink} activeClassName="active">
										OTHER EVENTS
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink onClick={this.onSelect} to="/register" tag={RRNavLink} activeClassName="active">
										REGISTER
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink onClick={this.onSelect} to="/contact" tag={RRNavLink} activeClassName="active">
										CONTACT
									</NavLink>
								</NavItem>
							</Nav>
						</Collapse>
					</Navbar>
				</Container>
				<Banner />
				<MessageRow {...this.props} />
			</Fragment>
		);
	}
}

export default Header;

const styles = StyleSheet.create({
	topWrapper: {
		height: 'auto',
		'@media (min-width: 992px)': {
			height: 110
		}
	},
	logo: {
		float: 'left',
		height: 80,
		margin: '15px 0px'
	}
});
