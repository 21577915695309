import React, { Component, Fragment } from 'react';
import ShortDescription from '../ShortDescription';
import ShortInfo from '../ShortInfo';
import Schedule from '../Schedule';
import VenueShortInfo from '../VenueShortInfo';

class HomePage extends Component {
	render() {
		return (
			<Fragment>
				<ShortDescription {...this.props} />
				<ShortInfo {...this.props} />
				<Schedule {...this.props} />
				<VenueShortInfo />
			</Fragment>
		);
	}
}

export default HomePage;
