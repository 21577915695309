import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import context from '../Context';
import 'react-tabs/style/react-tabs.css';
import './index.css';
import calendarIcon from './calendar.svg';

class Schedule extends Component {
	render() {
		return (
			<Container style={styles.wrapper}>
				<Row>
					<Col style={styles.top} xs="12">
						<img style={styles.img} src={calendarIcon} alt="" />
						<p style={styles.title}>
							SCHEDULE
						</p>
						<p style={styles.subTitle}>
							TUESDAY 13<sup>th</sup> NOVEMBER 2018
						</p>
					</Col>
					<Col xs="12">
						<Day
							morning={this.props.context.schedule.day1.morning}
							afternoon={this.props.context.schedule.day1.afternoon}
							evening={this.props.context.schedule.day1.evening}
						/>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default Schedule;

const Day = props => {
	return (
		<Tabs className='daytab'>
			<TabList style={styles.tabList}>
				{props.morning.length ? <Tab>Morning</Tab> : ''}
				{props.afternoon ? <Tab>Afternoon</Tab> : ''}
				{props.evening.length ? <Tab>Evening</Tab> : ''}
			</TabList>
			<br />
			{props.morning.length ? (
				<Col xs="12" sm={{offset: 1, size: 10}}> 
					<TabPanel style={styles.tabPanel}>
						{props.morning.map(elm => {
							return <DayRow data={elm} key={elm.hours} />
						})}
					</TabPanel>
				</Col>
			) : ''}
			{props.afternoon.length ? (
				<Col xs="12" sm={{offset: 1, size: 10}}> 
					<TabPanel style={styles.tabPanel}>
						{props.afternoon.map(elm => {
							return <DayRow data={elm} key={elm.hours} />
						})}
					</TabPanel>
				</Col>
			) : ''}
			{props.evening.length ? (
				<Col xs="12" sm={{offset: 1, size: 10}}> 
					<TabPanel style={styles.tabPanel}>
						{props.evening.map(elm => {
							return <DayRow data={elm} key={elm.hours} />
						})}
					</TabPanel>
				</Col>
			): ''}
		</Tabs>
	);
}

const DayRow = props => {
	return (
		<Row style={styles.dayRow}>
			<Col xs="12" sm="4">
				{props.data.hours}
			</Col>
			<Col xs="12" sm="8" className='text-justify'>
				<strong style={styles.dayRowTitle} dangerouslySetInnerHTML={{__html: props.data.title}}></strong>
				<br />
				<span dangerouslySetInnerHTML={{__html: props.data.desc}}></span>
			</Col>
		</Row>
	);
}

const styles = {
	wrapper: {
		paddingTop: 30
	},
	top: {
		paddingBottom: 30,
		textAlign: 'center',
		fontFamily: 'Montserrat'
	},
	img: {
		width: 50
	},
	title: {
		margin: 0,
		fontWeight: 'bold',
		fontSize: '1.5em',
		color: context.theme.palette.darkGrey
	},
	subTitle: {
		margin: 0,
		color: context.theme.palette.darkGrey
	},
	tabList: {
		textAlign: 'center'
	},
	tabPanel: {
		color: context.theme.palette.grey
	},
	dayRow: {
		borderTop: '1px solid #ddd',
		padding: '10px 0px 5px 0px',
		overflow: 'auto'
	},
	dayRowTitle: {
		color: context.theme.palette.darkGrey
	}
}
