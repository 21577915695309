import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import RegisterForm from '../RegisterForm';
import sideImg from './side-image.jpg';

class RegistrationPage extends Component {
	render() {
		return (
			<Container className={css(styles.wrapper)}>
				<Row>
					<Col xs="12" lg="6">
						<p>
							(Our team will contact you to confirm attendance)
						</p>
						<RegisterForm />
					</Col>
					<Col xs="12" lg="6">
						<img className={css(styles.img)} src={sideImg} alt="" />
					</Col>
				</Row>
			</Container>
		);
	}
}

export default RegistrationPage;

const styles = StyleSheet.create({
	wrapper: {
		paddingTop: 30,
		paddingBottom: 30
	},
	img: {
		width: '100%',
		paddingTop: 30,
		'@media (min-width: 992px)': {
			paddingTop: 0
		}
	}
});
