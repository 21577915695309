import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import context from '../Context';
import teamImg from './team.jpg';
import locationIcon from './location.svg';
import mailIcon from './mail.svg';
import phoneIcon from './phone.svg';
import linkedinLogo from './linkedin.svg';

class ContactPage extends Component {
	render() {
		return (
			<Container style={styles.wrapper}>
				<Row>
					<Col xs="12" md="6">
						<div style={styles.elmWrapper}>
							<img style={styles.elmImg} src={locationIcon} alt="" />
							<div style={styles.elmTextWrapper}>
								<div style={styles.elmTextTitle}>
									Office
								</div>
								<div style={styles.elmTextValue}>
									{context.siteVars.address.join(', ')}
								</div>
							</div>
						</div>
						<div style={styles.elmWrapper}>
							<img style={styles.elmImg} src={mailIcon} alt="" />
							<div style={styles.elmTextWrapper}>
								<div style={styles.elmTextTitle}>
									Email
								</div>
								<div style={styles.elmTextValue}>
									<a style={styles.elmTextLink} href={`mailto:${context.siteVars.email}`}>
										{context.siteVars.email}
									</a>
								</div>
							</div>
						</div>
						<div style={styles.elmWrapper}>
							<img style={styles.elmImg} src={phoneIcon} alt="" />
							<div style={styles.elmTextWrapper}>
								<div style={styles.elmTextTitle}>
									Phone
								</div>
								<div style={styles.elmTextValue}>
									<a style={styles.elmTextLink} href={`tel:${context.siteVars.phoneIntl}`}>
										{context.siteVars.phone}
									</a>
								</div>
							</div>
						</div>
						<div style={styles.elmWrapper}>
							<img style={styles.elmImg} src={linkedinLogo} alt="" />
							<div style={styles.elmTextWrapper}>
								<div style={styles.elmTextTitle}>
									LinkedIn
								</div>
								<div style={styles.elmTextValue}>
									<a style={styles.elmTextLink} href={context.siteVars.linkedInUrl}>
										{context.siteVars.linkedInText}
									</a>
								</div>
							</div>
						</div>
					</Col>
					<Col xs="12" md="6">
						<img style={styles.teamImg} src={teamImg} alt="" />
					</Col>
				</Row>
			</Container>
		);
	}
}

export default ContactPage;

const styles = {
	wrapper: {
		paddingTop: 30,
		paddingBottom: 30
	},
	elmWrapper: {
		marginBottom: 30
	},
	elmImg: {
		float: 'left',
		marginTop: 5,
		width: 40
	},
	elmTextWrapper: {
		marginLeft: 65
	},
	elmTextTitle: {
		fontSize: '1.3em',
		fontWeight: 'bold',
		color: context.theme.palette.charcoal
	},
	elmTextValue: {
		color: context.theme.palette.darkGrey
	},
	elmTextLink: {
		overflowWrap: 'break-word',
		textDecoration: 'none',
		color: context.theme.palette.darkGrey
	},
	teamImg: {
		width: '100%'
	}
}
