import React, { Component } from 'react';
import context from '../Context';
import { StyleSheet, css } from 'aphrodite';

const messages = {
	home: {
		msg: 'Housing Technology Congress 2018'
	},
	venue: {
		msg: 'Venue Information'
	},
	attendees: {
		msg: 'Attendees'
	},
	otherevents: {
		msg: 'Paul Clark Events'
	},
	register: {
		msg: 'Register to attend'
	},
	contact: {
		msg: 'Contact Us'
	}
}

class MessageRow extends Component {
	constructor() {
		super();
		this.state = {
			page: 'home'
		}
	}

	componentDidMount() {
		const setPage = (location) => {
			this.setState({
				page: location.pathname.replace(/^\//, '') || 'home'
			});
		}

		setPage(this.props.location);
		this.props.history.listen(setPage);
	}

	render() {
		return (
			<div className={css(styles.wrapper)}>
				{(messages[this.state.page]).msg || messages['home'].msg}
			</div>
		);
	}
}

export default MessageRow;

const styles = StyleSheet.create({
	wrapper: {
		padding: 15,
		backgroundColor: context.theme.palette.yellow,
		color: context.theme.palette.charcoal,
		fontSize: '1.3em',
		fontWeight: 'bold',
		textAlign: 'center',
		'@media (min-width: 768px)': {
			padding: 20,
			fontSize: '2em'
		}
	}
});
