import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

class ShortDescription extends Component {
	render() {
		return (
			<Container style={styles.wrapper}>
				<Row>
					{this.props.context.shortDescription.text.map((elm, index) => {
						return (
							<Col key={index} xs="12" md="6">
								{elm.map((elm, index, arr) => {
									return (
										<p key={index} className={(index === arr.length - 1) ? 'last' : ''}>
											{elm}
										</p>
									);
								})}
							</Col>
						);
					})}
				</Row>
			</Container>
		);
	}
}

export default ShortDescription;

const styles = {
	wrapper: {
		paddingTop: 30,
		paddingBottom: 30,
		textAlign: 'justify'
	}
}
