export const shortDescription = {
	text: [
		[
			'Housing Technology Congress UK will bring together invite only group of 30 CIO/IT Business leaders from UK Housing Associations to learn from each other on the foundations of housing providers operations from which technology provides a fundamental role.',
			'Revolutionise your organisation’s performance using the very latest technologies in disruptive technologies and process to transform housing providers operations, core business applications, GDPR and other regulatory developments, digital inclusion and digital by default, CRM, channel shift and tenant communications, business/digital transformation and housing as a business: comparing ideas from within and beyond the housing sector.'
		],
		[
			'Through a content-based platform of keynotes, panels, workshops and roundtables, alongside exhibits of leading innovations in housing technology, all built into one exciting day in the secluded and breathtaking surroundings of Bowood Luxury Hotel and Spa, Calne, Wiltshire.',
			'Network with like-minded professionals to support your current strategic objectives. Attendance will give you the chance to learn how best to implement, build and buy the right solutions and technologies that are at the forefront of the housing technology industry.',
			'We look forward to seeing you there!'
		]
	]
}

export const venueShortInfo = {
	location: {
		text: 'Housing Technology Congress 2018 will take place in the luxurious Bowood Hotel, Spa and Golf Resort, Wiltshire. Large, free car park for all meeting guests.',
	},
	transport: {
		text: '15 minutes from M4 Junction 17. Chippenham Railway Station is approximately 14 minutes away. Transport can be booked at JJ’s Taxis, Chippenham, 01249 446436.',
	},
	hotel: {
		text: 'Angel Hotel: 01249 652 615<br />Lucknam Park Hotel: 01225 742777<br />Premier Travel Inn: 08701 977 061<br />Beechfield House Hotel: 01225 703700',
	}
}

export const schedule = {
	day1: {
		morning: [
			{
				hours: '07:00 - 08:30',
				title: 'Registration and Welcome',
				desc: ''
			},
			{
				hours: '08:30 - 09:30',
				title: 'Breakfast Panel',
				desc: '<p>Disruptive technologies & processes to transform housing providers’ operations</p>'
			},
			{
				hours: '09:30 - 10:30',
				title: 'Vendor-Led Roundtables',
				desc: '<br /><p><strong>1, 2 & 3</strong></p>'
			},
			{
				hours: '10:30 - 10:50',
				title: 'Coffee & Networking',
				desc: ''
			},
			{
				hours: '10:50 - 11:30',
				title: 'Workshop 1',
				desc: '<p>Core business applications: mobile working, housing, finance & asset management, IoT, infrastructure, DR & security</p>'
			},
			{
				hours: '11:30 - 11:50',
				title: 'Coffee & Networking',
				desc: ''
			},
			{
				hours: '11:50 - 12:30',
				title: 'Workshop 2',
				desc: '<p>GDPR and other regulatory developments</p>'
			}
		],
		afternoon: [
			{
				hours: '12:30 - 13:30',
				title: 'Working Buffet Lunch & Networking',
				desc: ''
			},
			{
				hours: '13:30 - 14:10',
				title: 'Workshop 3',
				desc: '<p>Digital inclusion & ‘digital by default’</p>'
			},
			{
				hours: '14:10 - 14:30',
				title: 'Coffee & Networking',
				desc: ''
			},
			{
				hours: '14:30 - 15:10',
				title: 'Workshop 4',
				desc: '<p>CRM, channel shift and tenant communications</p>'
			},
			{
				hours: '15:10 - 15:30',
				title: 'Coffee & Networking',
				desc: ''
			},
			{
				hours: '15:30 - 16:10',
				title: 'Workshop 5',
				desc: '<p>Business/digital transformation</p>'
			},
			{
				hours: '16:10 - 16:30',
				title: 'Coffee & Networking',
				desc: ''
			},
			{
				hours: '16:30 - 17:10',
				title: 'Workshop 6',
				desc: '<p>Housing as a business: comparing ideas from within and beyond the housing sector</p>'
			},
			{
				hours: '17:10 - 18:10',
				title: 'Coffee & Networking',
				desc: ''
			}
		],
		evening: [
			{
				hours: '18:10 - 19:15',
				title: 'Cocktail Reception (out on the lawn) (live music)',
				desc: ''
			},
			{
				hours: '19:15 - 21:00',
				title: 'Gala Dinner',
				desc: ''
			},
			{
				hours: '21:00 - 22:00',
				title: 'Open Bar and Goodnight',
				desc: ''
			}
		]
	}
}
