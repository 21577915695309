import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import context from '../Context';
import bannerImg from './banner.jpg';
import logoSvg from '../images/htc-logo-white.svg';
import backgroundPattern from './background-pattern-yellow.svg';


class Banner extends Component {
	render() {
		return (
			<div className={css(styles.wrapper)}>
				<Container className={css(styles.container)}>
					<div className={css(styles.box1)}>
						Putting Technology
						<br />
						At The Heart Of Your
						<br />
						Internal And External Operations
					</div>
					<div className={css(styles.box2)}>
						13th November 2018
						<br />
						Bowood Hotel & Spa, Calne, Wiltshire, UK
					</div>
					<img className={css(styles.logo)} src={logoSvg} alt="" />
				</Container>
			</div>
		);
	}
}

export default Banner;

const styles = StyleSheet.create({
	wrapper: {
		backgroundColor: context.theme.containerDarkBackground,
		backgroundImage: `url(${backgroundPattern})`,
		backgroundSize: 40,
		fontFamily: 'Montserrat'
	},
	container: {
		position: 'relative',
		height: 300,
		fontWeight: 'bold',
		backgroundPosition: '45% top',
		backgroundImage: `url(${bannerImg})`,
		'@media (min-width: 768px)': {
			height: 400
		}
	},
	box1: {
		position: 'absolute',
		backgroundColor: 'rgba(55, 55, 53, 0.7)',
		left: '7%',
		top: 30,
		padding: '10px 20px',
		lineHeight: '1.5em',
		fontSize: '1.3em',
		color: context.theme.palette.yellow,
		'@media (min-width: 768px)': {
			padding: '20px 30px',
			fontSize: '2.2em'
		}
	},
	box2: {
		position: 'absolute',
		backgroundColor: 'rgba(55, 55, 53, 0.7)',
		left: '7%',
		top: 210,
		padding: '5px 10px',
		lineHeight: '1.5em',
		fontSize: '1.1em',
		color: '#fff',
		'@media (min-width: 768px)': {
			top: 300,
			padding: '10px 20px',
			fontSize: '1.4em'
		}
	},
	logo: {
		display: 'none',
		'@media (min-width: 992px)': {
			display: 'block',
			position: 'absolute',
			right: 10,
			bottom: 10,
			height: 65
		}
	}
});
