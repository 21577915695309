import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import context from '../Context';
import { StyleSheet, css } from 'aphrodite';
import pccLogo from '../images/pcc-logo-black.svg';
import linkedinLogo from './linkedin.svg';
import twitterLogo from './twitter.svg';
import facebookLogo from './facebook.svg';
import googlePlusLogo from './google-plus.svg';
import backgroundPattern from './background-pattern.svg';
import backgroundGradient from './background-gradient.svg';

class Footer extends Component {
	render() {
		return (
			<div className={css(styles.wrapper)} style={{backgroundColor: context.theme.palette.yellow}}>
				<Container>
					<Row>
						<Col className={css(styles.pccLogoWrapper)} xs="12" md="6">
							<img src={pccLogo} alt="" />
						</Col>
						<Col className={css(styles.pccMessageWrapper)} xs="12" md="6">
							<span className='color-charcoal' style={{fontSize: '1.35em', fontWeight: 'bold'}}>
								Supporting growth.
								<br />
								Engaging audiences.
								<br />
								Recognising talent.
							</span>
						</Col>
						<Col xs="12" style={{padding: '30px 0px'}}>
							<a href='https://www.linkedin.com/company/paul-clark-consulting/' target='_blank' rel='noopener noreferrer'>
								<img className={css(styles.socialMediaLogo)} src={linkedinLogo} alt="" />
							</a>
							<a href='https://twitter.com/paulclarkcons' target='_blank' rel='noopener noreferrer'>
								<img className={css(styles.socialMediaLogo)} src={twitterLogo} alt="" />
							</a>
							<a href='https://www.facebook.com/pg/Paul-Clark-Consulting-153436978698054' target='_blank' rel='noopener noreferrer'>
								<img className={css(styles.socialMediaLogo)} src={facebookLogo} alt="" />
							</a>
							<img className={css(styles.socialMediaLogo)} src={googlePlusLogo} alt="" />
						</Col>
						<Col xs="12">
							<p className={css(styles.smallText)}>
								Smart Industries UK Congress is a product of Paul Clark Sales Consulting Limited, a registered company in England and Wales, company number 10680877. Registered Office: {context.siteVars.address.join(', ')}
							</p>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default Footer;

const styles = StyleSheet.create({
	wrapper: {
		paddingTop: 40,
		backgroundImage: `url(${backgroundGradient}), url(${backgroundPattern})`,
		backgroundSize: '1000px, 40px',
		backgroundRepeat: 'no-repeat, repeat',
		backgroundPosition: 'center',
		textAlign: 'center'
	},
	pccLogoWrapper: {
		'@media (min-width: 768px)': {
			textAlign: 'right',
			borderRight: `5px dotted ${context.theme.palette.charcoal}`
		} 
	},
	pccMessageWrapper: {
		marginTop: 20,
		'@media (min-width: 768px)': {
			textAlign: 'left',
			marginTop: 0
		}
	},
	socialMediaLogo: {
		height: 48,
		margin: '0px 10px'
	},
	smallText: {
		fontSize: '0.65em',
		margin: 0
	}
});
