import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, ButtonGroup, Input, Button } from 'reactstrap';
import context from '../Context';
import loaderImg from './loader.gif';
import attendeesIcon from './attendees.svg';

class AttendeesPage extends Component {
	constructor() {
		super();
		this.state = {
			attendees: [],
			pwd: ''
		}
	}

	setPwd(val) {
		this.setState({pwd: val}, this.fetchAttendees);
	}

	componentWillMount() {
		let attendeesPwd = window.localStorage.getItem('AttendeesPwd');
		if (attendeesPwd) {
			this.setState({pwd: attendeesPwd}, this.fetchAttendees);
		}
	}

	fetchAttendees() {
		fetch('/api/store/json/attendees', {
			method: 'GET',
			headers: {
				'Authorization': 'Basic ' + btoa('guest:' + this.state.pwd)
			}
		})
		.then(res => res.json())
		.then(attendees => {
			this.setState({
				attendees: attendees
			});
			window.localStorage.setItem('AttendeesPwd', this.state.pwd);
		})
		.catch(err => {
			console.log(err);
			this.setState({pwd: ''});
			window.localStorage.setItem('AttendeesPwd', '');
		});
	}

	render() {
		return (
			<Container>
				{!this.state.pwd ? (
					<Credentials setPwd={this.setPwd.bind(this)} />
				) : (
					!this.state.attendees.length ? (
						<Loader />
					) : (
						<AttendeesList attendees={this.state.attendees} />
					)
				)}
			</Container>
		);
	}
}

export default AttendeesPage;

class Credentials extends Component {
	constructor() {
		super();
		this.state = {
			pwd: ''
		}
	}

	onChange(e) {
		this.setState({pwd: e.target.value});
	}

	setPwd(e) {
		e.preventDefault();
		this.props.setPwd(this.state.pwd);
	}

	render() {
		return (
			<Row style={styles.credentialsWrapper}>
				<Col xs={{offset: 1, size: 10}} sm={{offset: 3, size: 6}} md={{offset: 4, size: 4}}>
					<Form onSubmit={e => this.setPwd(e)}>
						<ButtonGroup style={styles.buttonGroup}>
							<Input type='password' style={styles.credentialsInput} value={this.state.pwd} onChange={this.onChange.bind(this)} />
							<Button style={styles.credentialsBtn} type='submit'>OK</Button>
						</ButtonGroup>
					</Form>
				</Col>
			</Row>
		);
	}
}

const Loader = props => {
	return (
		<Row style={styles.loaderWrapper}>
			<Col xs="12">
				<img src={loaderImg} alt="" />
			</Col>
		</Row>
	);
}

const AttendeesList = props => {
	return (
		<Fragment>
			<Row style={{textAlign: 'center', padding: '30px 0px'}}>
				<Col xs="12">
					<img src={attendeesIcon} style={{width: 50}} alt='' />
				</Col>
			</Row>
			{props.attendees.map((elm, index) => {
				return <AttendeesRow data={elm} index={index} key={index} />
			})}
			<br />
		</Fragment>
	);
}

const AttendeesRow = props => {
	return (
		<Row style={{...styles.attendeesRowWrapper, color: (props.index % 2 === 0 ? context.theme.palette.charcoal : context.theme.palette.grey)}}>
			<Col xs="12" sm={{offset: 1, size: 3}}>
				<strong>
					{props.data.company}
				</strong>
			</Col>
			<Col xs="12" sm="4">
				{props.data.position}
			</Col>
			<Col xs="12" sm="4">
				{props.data.name}
			</Col>
		</Row>
	);
}

const styles = {
	credentialsWrapper: {
		paddingTop: 30,
		paddingBottom: 30
	},
	buttonGroup: {
		width: '100%'
	},
	credentialsInput: {
		borderRadius: 0,
		border: `1px solid ${context.theme.palette.grey}`,
		backgroundColor: context.theme.palette.grey,
		color: '#fff'
	},
	credentialsBtn: {
		borderRadius: 0,
		border: `1px solid ${context.theme.palette.yellow}`,
		backgroundColor: context.theme.palette.yellow,
		fontWeight: 'bold',
		color: '#000'
	},
	loaderWrapper: {
		paddingTop: 30,
		paddingBottom: 30,
		textAlign: 'center'
	},
	attendeesRowWrapper: {
		paddingBottom: 15
	}
}
