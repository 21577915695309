import React, { Component } from 'react';
import { Col, Form, FormGroup, Label, Input, Alert, Button } from 'reactstrap';
import context from '../Context';

class RegisterForm extends Component {
	constructor() {
		super();
		this.state = {
			name: '',
			company: '',
			position: '',
			email: '',
			mobile: '',
			landline: '',
			consent: true,
			fail: false,
			failSubmit: false,
			success: false
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(e) {
		const states = ['name', 'company', 'position', 'email', 'mobile'];

		this.setState({
			fail: false,
			failSubmit: false,
			success: false
		});

		e.preventDefault();

		let check = states.every(elm => {
			return this.state[elm];
		});

		if (!check) {
			return this.setState({fail: true});
		}

		fetch('/email/register', {
			credentials: 'include',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				website: context.siteVars.website,
				name: this.state.name,
				company: this.state.company,
				position: this.state.position,
				email: this.state.email,
				mobile: this.state.mobile,
				landline: this.state.landline,
				consent: this.state.consent
			})
		}).then((res) => {
			this.setState({success: true});
			[...states, 'landline'].forEach(elm => {this.setState({[elm]: ''})});
		}).catch((err) => {
			console.log(err);
			this.setState({failSubmit: true});
		});
	}

	onChange(e) {
		this.setState({[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value});
	}

	render() {
		return (
			<Form>
				<FormGroup style={styles.row} row>
					<Label style={styles.label} xs="4" sm="3">
						Name&nbsp;
						<strong style={styles.required}>*</strong>
					</Label>
					<Col style={styles.col} xs="8" sm="9">
						<Input style={styles.input} type="text" name="name" value={this.state.name} onChange={this.onChange} />
					</Col>
				</FormGroup>
				<FormGroup style={styles.row} row>
					<Label style={styles.label} xs="4" sm="3">
						Company&nbsp;
						<strong style={styles.required}>*</strong>
					</Label>
					<Col style={styles.col} xs="8" sm="9">
						<Input style={styles.input} type="text" name="company" value={this.state.company} onChange={this.onChange} />
					</Col>
				</FormGroup>
				<FormGroup style={styles.row} row>
					<Label style={styles.label} xs="4" sm="3">
						Position&nbsp;
						<strong style={styles.required}>*</strong>
					</Label>
					<Col style={styles.col} xs="8" sm="9">
						<Input style={styles.input} type="text" name="position" value={this.state.position} onChange={this.onChange} />
					</Col>
				</FormGroup>
				<FormGroup style={styles.row} row>
					<Label style={styles.label} xs="4" sm="3">
						Email&nbsp;
						<strong style={styles.required}>*</strong>
					</Label>
					<Col style={styles.col} xs="8" sm="9">
						<Input style={styles.input} type="text" name="email" value={this.state.email} onChange={this.onChange} />
					</Col>
				</FormGroup>
				<FormGroup style={styles.row} row>
					<Label style={styles.label} xs="4" sm="3">
						Mobile&nbsp;
						<strong style={styles.required}>*</strong>
					</Label>
					<Col style={styles.col} xs="8" sm="9">
						<Input style={styles.input} type="text" name="mobile" value={this.state.mobile} onChange={this.onChange} />
					</Col>
				</FormGroup>
				<FormGroup style={styles.row} row>
					<Label style={styles.label} xs="4" sm="3">
						Office
					</Label>
					<Col style={styles.col} xs="8" sm="9">
						<Input style={styles.input} type="text" name="landline" value={this.state.landline} onChange={this.onChange} />
					</Col>
				</FormGroup>
				<FormGroup style={{...styles.row, textAlign: 'justify', fontSize: '.9em'}} check>
					<Col xs="12">
						<Input type="checkbox" name="consent" checked={this.state.consent} onChange={this.onChange} />
						I understand that by registering for this event I will be providing my data to the events organiser.I consent to this personal data being transferred and accept that other attendees as well as exhibitors or sponsors may contact me about their projects or services after the event. I agree that the attendee or sponsor may transfer my data outside of the European Economic Area for these purposes and I consent to such transfer. I also understand and agree that my personal data may be held and used by the organiser of this event, and any third party hosting provider acting on its behalf, in order to stage the event and to analyse visitor traffic with a view to improving the event experience for participants.
					</Col>
				</FormGroup>
				<div style={{textAlign: 'center'}}>
					{this.state.fail ? (<Alert color="danger" style={styles.alert}>Please complete all required fields!</Alert>) : ''}
					{this.state.failSubmit ? (<Alert color="danger" style={styles.alert}>Something went wrong! Please call us to register you.</Alert>) : ''}
					{this.state.success ? (<Alert color="success" style={styles.alert}>Thank you for registering!</Alert>) : ''}
					<Button style={styles.button} onClick={this.onSubmit}>
						Register
					</Button>
				</div>
			</Form>
		);
	}
}

export default RegisterForm;

const styles = {
	row: {
		margin: '0px 0px 15px 0px'
	},
	label: {
		color: '#fff',
		backgroundColor: '#000',
		textAlign: 'center'
	},
	required: {
		color: '#f00'
	},
	col: {
		padding: 0
	},
	input: {
		margin: 0,
		padding: 7,
		backgroundColor: context.theme.palette.grey,
		border: 0,
		borderRadius: 0,
		color: '#fff'
	},
	alert: {
		marginBottom: 15
	},
	button: {
		border: 0,
		borderRadius: 0,
		fontWeight: 'bold',
		color: '#000',
		backgroundColor: context.theme.palette.yellow
	}
}
