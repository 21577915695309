import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import attendeesSvg from './attendees.svg';
import microphoneSvg from './microphone.svg';
import calendarSvg from './calendar.svg';
import sponsorsSvg from './sponsors.svg';

const info = [
	{
		value: 30,
		text: 'Attendees',
		icon: attendeesSvg
	},
	{
		value: 5,
		text: 'Speakers',
		icon: microphoneSvg
	},
	{
		value: 10,
		text: 'Sessions',
		icon: calendarSvg
	},
	{
		value: 5,
		text: 'Solution Providers',
		icon: sponsorsSvg
	}
]

class ShortInfo extends Component {
	render() {
		return (
			<div style={{backgroundColor: this.props.context.theme.containerDarkBackground}}>
				<Container style={styles.wrapper}>
					<Row>
						{info.map(elm => {
							return <Box data={elm} key={elm.text} />
						})}
					</Row>
				</Container>
			</div>
		);
	}
}

export default ShortInfo;

const Box = props => {
	return (
		<Col style={styles.boxWrapper} xs="6" md="3">
			<p style={styles.boxValue}>
				{props.data.value}
			</p>
			<p style={styles.boxText}>
				{props.data.text}
			</p>
			<p>
				<img style={styles.boxSvg} src={props.data.icon} alt={props.data.text} />
			</p>
		</Col>
	);
}

const styles = {
	wrapper: {
		paddingTop: 30,
		paddingBottom: 30,
		color: '#fff'
	},
	boxWrapper: {
		padding: '30px 0px',
		textAlign: 'center'
	},
	boxValue: {
		fontSize: '2em',
		fontWeight: 'bold'
	},
	boxText: {
		fontWeight: 'bold'
	},
	boxSvg: {
		width: 50
	}
}
