import React from 'react';
import { shortDescription, venueShortInfo, schedule } from './Data';
import { siteVars } from './SiteVars';
import { theme } from './Theme';

const context = {
	shortDescription: shortDescription,
	venueShortInfo: venueShortInfo,
	schedule: schedule,
	siteVars: siteVars,
	theme: theme
}

export default context;

const Context = React.createContext(context);

export function withContext(Component) {
	return props => {
		return (
			<Context.Consumer>
				{context => <Component {...props} context={context} />}
			</Context.Consumer>
		);
	}
}
