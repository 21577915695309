export const siteVars = {
	website: 'Housing Technology Congress 2018',
	email: 'info@paulclarkconsulting.co.uk',
	phone: '01225 899 061',
	phoneIntl: '+44-01225-899-061',
	address: [
		'16 Market Street',
		'Bradford on Avon',
		'Wiltshire',
		'BA15 1LL'
	],
	linkedInUrl: 'https://www.linkedin.com/company/11058413',
	linkedInText: 'linkedin.com/company/11058413'
}
