import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import { withContext } from './Context';
import { Container } from 'reactstrap';
import Header from './Header';
import HomePage from './HomePage';
import AttendeesPage from './AttendeesPage';
import VenuePage from './VenuePage';
import OtherEventsPage from './OtherEventsPage';
import RegistrationPage from './RegistrationPage';
import ContactPage from './ContactPage';
import Footer from './Footer';

class App extends Component {
	render() {
		return (
			<Container style={styles.wrapper} fluid>
				<Header {...this.props} />
				<Route exact path="/" component={withContext(HomePage)} />
				<Route exact path="/attendees" component={AttendeesPage} />
				<Route exact path="/venue" component={VenuePage} />
				<Route exact path="/otherevents" component={OtherEventsPage} />
				<Route exact path="/register" component={RegistrationPage} />
				<Route exact path="/contact" component={ContactPage} />
				<Footer />
			</Container>
		);
	}
}

export default withRouter(withContext(App));

const styles = {
	wrapper: {
		padding: 0
	}
}
