import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import context from '../Context';
import venueImg1 from './venue-1.jpg';
import venueImg2 from './venue-2.jpg';
import venueImg3 from './venue-3.jpg';

class VenuePage extends Component {
	render() {
		return (
			<Container style={styles.wrapper}>
				<Row>
					<Col xs="12" md="6">
							<p>
								Set in spectacular countryside, Bowood is an exceptional venue for meetings and corporate events in Wiltshire.
							</p> 
							<p>
								Offering extensive meeting facilities, a 43 bedroom hotel and a private lodge, all set in acres of immaculate grounds, the estate offers a truly unique event experience for your delegates.
							</p>
							<p>
								Bowood is also easy to get to – either by train to Chippenham station (1 hour 15 minutes from London Paddington) or via road from the M4, A4, M3 and A303 – putting it within easy driving distance of large cities and towns like London, Bath, Bristol, Cardiff and Swindon.
							</p>
							<ul>
								<li>
									Truly secluded, distraction free location.
								</li>
								<li>
									Stunning entrance and beautiful surroundings – quality reflection of your company brand.
								</li>
								<li>
									Range of quality conference suites and meeting rooms holding from 4 to 240.
								</li>
								<li>
									All meeting rooms high spec with free WiFi and natural daylight.
								</li>
								<li>
									High quality and hugely flexible in-house catering prepared by our team of chefs.
								</li>
								<li>
									Boutique hotel, perfect for overnight meetings.
								</li>
								<li>
									Championship golf course and relaxing spa if you want to combine business with pleasure.
								</li>
								<li>
									Large free car park for all meeting guests.
								</li>
							</ul>
						<h4 style={styles.h}>
							Address
						</h4>
						<p>
							Bowood, Calne, Wiltshire
							<br />
							Bowood House: SN11 0LZ
							<br />
							Bowood Hotel: SN11 9PQ
						</p>
						<h4 style={styles.h}>
							Telephone
						</h4>
						<p>
							Bowood House: 01249 812102
							<br />
							Bowood Hotel: 01249 822228
						</p>
						<h4 style={styles.h}>
							Email
						</h4>
						<p>
							Bowood House: <a href="mailto:reception@bowood.org">reception@bowood.org</a>
							<br />
							Bowood Hotel: <a href="mailto:resort@bowood.org">resort@bowood.org</a>
						</p>
					</Col>
					<Col xs="12" md="6">
						<img style={styles.img} src={venueImg1} alt="" />
						<img style={styles.img} src={venueImg2} alt="" />
						<img style={{...styles.img, marginBottom: 0}} src={venueImg3} alt="" />
					</Col>
				</Row>
			</Container>
		);
	}
}

export default VenuePage;

const styles = {
	wrapper: {
		paddingTop: 30,
		paddingBottom: 30,
		textAlign: 'justify'
	},
	h: {
		padding: '10px 0px 0px 0px',
		fontSize: '1.3em',
		fontWeight: 'bold',
		color: context.theme.palette.grey
	},
	img: {
		width: '100%',
		marginBottom: 15
	}
}
