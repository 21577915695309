import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import context from '../Context';
import locationSvg from './location.svg';
import transportSvg from './transport.svg';
import hotelSvg from './hotel.svg';


const data = [
	{
		icon: locationSvg,
		title: 'Location',
		text: context.venueShortInfo.location.text,
		background: context.theme.palette.grey
	},
	{
		icon: transportSvg,
		title: 'Transport',
		text: context.venueShortInfo.transport.text,
		background: context.theme.palette.darkGrey
	},
	{
		icon: hotelSvg,
		title: 'Hotel',
		text: context.venueShortInfo.hotel.text,
		background: context.theme.palette.charcoal
	},
]

class VenueShortInfo extends Component {
	render() {
		return (
			<Container style={styles.wrapper}>
				<Row>
					<Col style={styles.topWrapper} xs="12">
						<p style={styles.title}>
							VENUE INFORMATION
						</p>
						<p style={styles.address}>
							Set in the picturesque grounds of Bowood Luxury Hotel and Spa, Calne, Wiltshire
						</p>
					</Col>
				</Row>
				<Row style={styles.boxesWrapper}>
						{data.map(elm => {
							return <Box data={elm} key={elm.title} />
						})}
				</Row>
			</Container>
		);
	}
}

export default VenueShortInfo;

const Box = props => {
	return (
		<Col xs="12" md="4" style={styles.boxParent}>
			<div style={{...styles.boxChild, backgroundColor: props.data.background}}>
				<img style={styles.boxSvg} src={props.data.icon} alt={props.data.title} />
				<br />
				<p style={styles.boxTitle}>
					{props.data.title}
				</p>
				<p style={styles.boxText} dangerouslySetInnerHTML={{__html: props.data.text}}></p>
				<br />
			</div>
		</Col>
	);
}

const styles = {
	wrapper: {
		paddingTop: 30,
		paddingBottom: 30
	},
	topWrapper: {
		paddingBottom: 30,
		textAlign: 'center'
	},
	title: {
		margin: 0,
		fontSize: '1.5em',
		fontWeight: 'bold',
		color: context.theme.palette.darkGrey
	},
	address: {
		margin: 0,
		color: context.theme.palette.grey
	},
	boxesWrapper: {
		overflow: 'hidden'
	},
	boxParent: {
		marginBottom: '-99999px',
		paddingBottom: '99999px'
	},
	boxChild: {
		padding: 30,
		margin: 'auto',
		color: '#fff',
		marginBottom: '-99999px',
		paddingBottom: '99999px'
	},
	boxSvg: {
		width: 50
	},
	boxTitle: {
		fontSize: '1.5em',
		fontWeight: 'bold'
	},
	boxText: {
		fontAlign: 'justify'
	}
}
